<script>
import { mapState, mapGetters } from 'vuex';
import CartItemContainer from '@/components/CartItemContainer.vue';
import axios from 'axios';

export default {
  data() {
    return {
      postage: 0,
    };
  },
  props: {
    vat: {
      type: Number,
      required: false,
    },

    delivery: {
      type: Number,
      required: false,
    },

    showVat: {
      type: Boolean,
      default: false,
    },

    showTotal: {
      type: Boolean,
      default: false,
    },

    isEditable: {
      type: Boolean,
      default: true,
    },
  },
  

  async created() {
    this.getPostage();
  },
  computed: {
    ...mapState(['currency']),
    ...mapState('cart', ['items']),
    ...mapState('checkout', ['customer']),
    ...mapGetters('cart', ['sumTotal']),
    

    totalSum() {

      let vat = 0;
      let delivery = 0;



      if(this.customer.type === "company"){
        // Type 1
        if (this.customer.country.code == "SE") {
          vat = 25
        }
        // Type 2
        else if(this.customer.country.is_eu === 1 && this.customer.orgnr){
          vat = 0
        }
        // Type 3
        else if (this.customer.country.is_eu === 1 && !this.customer.orgnr) {
          vat = 25
        }
        // Type 4
        else if (this.customer.country.is_eu === 0) {
          vat = 0
        }
      } else {
        // Type 5
        if(this.customer.country.code == "SE"){
          vat = 25
        }
        // Type 6
        else if(this.customer.country.is_eu === 1){
          vat = this.customer.country.vat
        }
        // Type 7
        else if (this.customer.country.code == "NO") {
          vat = this.customer.country.vat
        }
        // Type 8
        else if (this.customer.country.is_eu === 0) {
          vat = 0
          delivery = 80
        }
      }

      console.log(vat, this.customer.country.is_eu, this.customer.orgnr, "vat")

        
 
      // let vat = this.customer.orgnr == null ? 0 : this.customer.country.vat || 0;
      // if (this.customer.type === "company") {
      //   if (this.customer.orgnr && this.customer.country.is_eu === 0) {
      //     vat = 0;
      //   } 
      //   else if(this.customer.type === "company" && this.customer.orgnr === "" && this.customer.country.is_eu === 1){
      //     vat = 25;
      //   }
      //   else if (!this.customer.orgnr && this.customer.country.is_eu === 0) {
      //     vat = 25;
      //   }
      // }
     
      let totalSum = 0;
      // if (delivery) {
      //   totalSum = (this.sumTotal + this.sumTotal * (this.customer.country.vat / 100) + delivery) / 100;
      // } else {
      //   totalSum = this.sumTotal;
      // }
      totalSum = this.sumTotal;


      // if (this.customer.country.is_eu == 0) {
      //   totalSum += this.postage;
      // }




     if (vat !== 0) {
        totalSum += this.sumTotal * (vat / 100); // add vat only if vat is not 0
      } 
      return totalSum + delivery;
    },

    
    

    getPostage() {
      // eslint-disable-next-line vue/no-async-in-computed-properties
      axios.get(`${process.env.VUE_APP_BACKEND_URL}/api/postage`).then((res) => {
        this.postage = res.data;
        // console.log('postage is  ', this.postage);
      });
    },

    vatSum() {
      return this.sumTotal * (this.customer.country.vat / 100);
    },
  },

  components: { CartItemContainer },
  
};
</script>

<template>
  <div class="my-6 mx-auto max-w-xl">
    <div class="bg-white m-auto max-w-xl shadow-md">
      <div class="p-6">
        <CartItemContainer
          v-for="(image, i) in items"
          :key="i"
          v-model="items[i]"
          :canDelete="isEditable"
        />
      </div>
   
     <!-- <div class="flex justify-between px-4 py-2 text-sm"> {{ !customer.country.is_eu && this.customer.type != "company" ? $t('checkout.orderFeePeron') : ''}}</div> -->
      <hr />

        <div
            v-if="this.customer.type === 'personal' && this.customer.country.is_eu === 0 && this.customer.country.code != 'NO' && this.customer.country.code != 'SE'"
            class="flex justify-between px-4 py-2 text-sm"
          >
            <span>{{ $t('checkout.postage') }}</span>
            <span>{{ postage }} {{ currency.toUpperCase() }}</span>
          </div>
      <div>
        <div class="flex justify-between px-4 py-2 text-sm">
          <span>{{ $t('checkout.subtotal') }}</span>
          
          <span v-if="this.customer.type === 'personal' && this.customer.country.is_eu === 0 && this.customer.country.code != 'NO' && this.customer.country.code != 'SE'">{{ sumTotal + postage }} {{ currency.toUpperCase() }}</span>
          <span v-else>{{ sumTotal }} {{ currency.toUpperCase() }}</span>
        </div>

       

        <div class="flex justify-between px-4 py-2 text-sm" v-if="showVat">
          
          <!-- Type 1 -->
          <span  v-if="this.customer.type === 'company' && this.customer.country.code == 'SE' ">
            <span>{{ $t('checkout.vat') }} (25%)</span>
          </span>

          <!-- Type 2 -->
          <span v-else-if="this.customer.type === 'company' && this.customer.orgnr && this.customer.country.is_eu === 1">
            <span>{{ $t('checkout.vat') }} (0%)</span>
          </span>

          <!-- Type 3 -->
          <span v-else-if="this.customer.type === 'company' && !this.customer.orgnr && this.customer.country.is_eu === 1">
            <span>{{ $t('checkout.vat') }} (25%)</span>
          </span>
          
          <!-- Type 4 -->
          <span v-else-if="this.customer.type === 'company' && this.customer.country.is_eu === 0">
            <span>{{ $t('checkout.vat') }} (0%)</span>
          </span>

          <!-- Type 5 -->
          <span v-else-if="this.customer.type === 'personal' && this.customer.country.code == 'SE'">
            <span>{{ $t('checkout.vat') }} (25%)</span>
          </span>
          
          <!-- Type 6 -->
          <span v-else-if="this.customer.type === 'personal' && this.customer.country.is_eu === 1">
            <span>{{ $t('checkout.vat') }} ({{ this.customer.country.vat }}%)</span>
          </span>
     
          <!-- Type 7 -->
          <span v-else-if="this.customer.type === 'personal' && this.customer.country.code == 'NO'">
              <span>{{ $t('checkout.vat') }} (0%)</span>
          </span>

          <!-- Type 8 -->
          <span v-else-if="this.customer.type === 'personal' && this.customer.country.is_eu === 0">
            <span>{{ $t('checkout.vat') }} (0%)</span>
          </span>

          

     
          <!-- Type 1 -->
          <span v-if="this.customer.type === 'company' && this.customer.country.code == 'SE'">
              {{ (sumTotal * 25) / 100 }} {{ currency.toUpperCase() }}
          </span>

          <!-- Type 2 -->
          <span v-else-if="this.customer.type === 'company' && this.customer.orgnr && this.customer.country.is_eu === 1">
              0 {{ currency.toUpperCase() }}
          </span>

          <!-- Type 3 -->
          <span v-else-if="this.customer.type === 'company' && !this.customer.orgnr && this.customer.country.is_eu === 1">
               {{ (sumTotal * 25) / 100 }} {{ currency.toUpperCase() }}
          </span>

          <!-- Type 4 -->
          <span v-else-if="this.customer.type === 'company' && this.customer.country.is_eu === 0">
            0 {{ currency.toUpperCase() }}
          </span>

          <!-- Type 5 -->
          <span v-else-if="this.customer.type === 'personal' && this.customer.country.code == 'SE'">
            {{ (sumTotal * 25) / 100 }} {{ currency.toUpperCase() }}
          </span>

          <!-- Type 6 -->
          <span v-else-if="this.customer.type === 'personal' && this.customer.country.is_eu === 1">
           {{ vatSum.toString().replace('.', ',') }} {{ currency.toUpperCase() }}
          </span>

          <!-- Type 7 -->
          <span v-else-if="this.customer.type === 'personal' && this.customer.country.code == 'NO'">
           0 {{ currency.toUpperCase() }}
          </span>

          <!-- Type 8 -->
          <span v-else-if="this.customer.type === 'personal' && this.customer.country.is_eu === 0">
           0 {{ currency.toUpperCase() }}
          </span>
       
        </div>

        <!-- <div class="flex justify-between px-4 py-2 text-sm" v-if="delivery">
          <span>{{ $t('checkout.delivery') }}</span>
          <span v-if="delivery !== null">{{ delivery / 100 }} {{ currency.toUpperCase() }}</span>
          <span v-else>N/A</span>
        </div> -->

        <div class="flex justify-between px-4 py-2" v-if="showTotal">
          <span>{{ $t('checkout.total') }}</span>
          <span>{{ totalSum }} SEK</span>
        </div>
      </div>
    </div>
  </div>
</template>
