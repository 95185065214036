<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    data: {
      type: Object,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      input: this.value || '',
      hasError: false,
    };
  
  },

  watch: {
    input(val) {
      this.$emit('input', val);
    },
  },

  methods: {
   validate() {
      let isValid = true;

      if (this.data.required) {
        let okLength = this.input.length > 0;
        let okValidation = true;
        if (this.data.validate) {
          okValidation = this.data.validate(this.input);
        }


        // Check if it's an email field and perform email validation
        if (this.$t(`checkout.form.${this.data.name}`) === 'Email' || this.$t(`checkout.form.${this.data.name}`) === 'E-postadress') {
          okValidation = this.validateEmail(this.input);
        }
        isValid = okLength && isValid && okValidation;
      }
   
      this.hasError = !isValid;
      return this.hasError;
    },

    validateEmail(email) {
      // Regular expression to validate email
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },
    

    onInput() {
      this.$emit('onInput');
    },
  },
};
</script>

<template>
  <input
    class="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
    :class="{ 'border-red-500': hasError }"
    :id="`builder-input-${data.name}`"
    :type="$t(`checkout.form.${data.name}`) === 'Email' || $t(`checkout.form.${data.name}`) === 'E-postadress' ? 'email' : 'text'"
    :placeholder="$t(`checkout.form.${data.name}`)"
    v-model="input"
    @input="onInput"
     v-if="!disabled"
  />

  <input
    v-else
    class="appearance-none block w-full bg-gray-200 text-gray-500 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
    v-model="input"
    :id="`builder-input-${data.name}`"
    disabled
  />
</template>

<style>
</style>