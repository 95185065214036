<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import {i18n} from "vue-lang-router";

export default {
  name: 'newsletter',

  data() {
    return {
      name: '',
      email: '',
    };
  },

  created() {
    document.title = i18n.t("newsletter") + " | " + i18n.t("tempus");
  },

  computed: {
    ...mapGetters(['isLoading']),

  },

  methods: {
    send() {
      if (this.name == '' || this.email == '') {
        alert('Please complete all fields');
        return;
      }
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(this.email)) {
        alert('Email field must have email form');
        return;
      }
      axios.post(`${process.env.VUE_APP_BACKEND_URL}/api/newsletter/save`,
        { name: this.name, email: this.email }).then(() => {
          this.name = "";
          this.email = "";
        alert('Saved');
      });
    },
  },

  components: {},
};
</script>

<template>
  <div class="illustration-info-container">
    <div class="illustration-info">
      <div class="content">
        <div class="header p-4">
          <h1 class="text-3xl">Nyhetsbrev</h1>
        </div>
        <br>
        <div class="content-paragraph">
          Tempus Bildbyrås webbplats och utbud utvecklas och utökas hela tiden. Anmäl dig till nyhetsbrevet för att
          fortlöpande få nyheter via e-post. Du kan givetvis när som helst avanmäla dig.
        </div>
        <div>
          <form>
            <table class="newsletter-table">
              <tr>
                <td>Namn:</td>
                <td><input v-model="name" class="newsletter-input" type="text" name="name"></td>
              </tr>
              <tr>
                <td>E-postadress:</td>
                <td><input v-model="email" class="newsletter-input" type="email" name="email">
                </td>
              </tr>
              <tr>
                <td></td>
                <td>
                  <div class="newsletter-button" @click="send()">
                    Skicka
                  </div>
                </td>
              </tr>
            </table>

          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.illustration-info-container {
  text-align: left;
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.05);

  .illustration-info {
    // width: 75%;
    margin: 0 25%;
    height: 100vh;
    // margin: 0 auto 0;
    background-color: #fff;

    @media only screen and (max-width: 992px){
      margin: 0 20px;
      width: 100%;
      height: 100%;
    }

    @media only screen and (max-width: 600px){
      width: auto;
    }

    // @media screen and (max-width: $bp-mobile) {
    //   width: 100%;
    //   margin: 0;
    // }

    .header {
      span {
        margin: 0 1em;
        cursor: pointer;
      }

      h3 {
        padding: 1em;
      }
    }

    .content {
      padding: 1em;

      h1{
        font-size: 30px;
         font-weight: 500;
        line-height: 36px;
      }

      .content-paragraph{
        font-size: 16px;
        font-weight: 500;
        line-height: 29px;
      }

      div {
        padding: 1em;
      }

      .about {
        p {
          margin: 0;
          font-size: 0.9em;
          line-height: 1.4em;
        }
      }

      .category {
        div {
          padding: 0;
          margin: 1em 0;

          &:last-of-type {
            margin-bottom: 0;
            border-bottom: none;
          }
        }
      }

      .description {
        background-color: #dfe6e9;
        padding-left: 0;
        padding-right: 0;
      }

      .tags {
        display: block;

        .badge {
          display: inline-block;
          font-size: 0.75em;
          padding: 0.5em 0.75em;
          border-radius: 5px;
          color: #fff;
          margin: 0 0.5em 0.5em 0;
          background-color: #1c1c68;
        }
      }
    }
  }

  .newsletter-input {
    border: 1px solid #2596be;
    border-radius: 0.25rem;
    padding: 0.2em;
  }

  .newsletter-table {
    border: none;

    tr {
      height: 35px;
    }

    td {
      padding: 0.2em;
    }
  }

  .newsletter-button {
    background: #2596be;
    border-radius: 0.25rem;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 26px;
    cursor: pointer;
  }

}
</style>
