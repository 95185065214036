<script>
import BuilderInput from './BuilderInput.vue';

export default {
  
  props: {
    value: {
      type: Object,
      required: true,
    },
    blueprint: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  

  data() {
    return {
      errors: [],
      billing_info: this.value,
    };

   
  },
  

  methods: {
    isValid() {
      this.errors = this.$refs.input.reduce((acc, curr) => {
        if (curr.validate()) {
          acc.push(curr.data.name);
        }
        return acc;
      }, []);
      return this.errors.length === 0;
      
    },

    onInput() {
      this.$emit('onUpdate');
    },
    
  },
  

  components: { BuilderInput },
};
</script>

<template>
  <form>
    <div v-for="(section, i) in blueprint.sections" :key="i">
      <div class="flex flex-wrap -mx-3 mb-6" v-for="(row, j) in section" :key="j">
        <div class="px-3 mb-6 flex-auto" v-for="(field, k) in row" :key="k">
          <label
            class="block text-left uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            for="grid-first-name"
          >
            {{ $t(`checkout.form.${field.name}`) }}
            <span v-if="field.required">*</span>
          </label>

          <BuilderInput
            ref="input"
            v-model="billing_info[field.name]"
            :data="field"
            @onInput="onInput"
            :disabled="disabled"
          />
        </div>
      </div>
    </div>
  </form>
</template>

<style></style>
