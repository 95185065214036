<script>
import { mapActions, mapState } from 'vuex';
import Builder from './forms/Builder.vue';
import PersonalBp from './forms/personal_summary';
import CompanyBp from './forms/company_summary';
import CompanySeBp from './forms/company_se_summary';
import CartSummary from '../components/CartSummary.vue';
import newsletter from '../../newsletter/Newsletter.vue';

export default {
  data() {
    return {
      form_map: {
        personal: PersonalBp,
        company: CompanyBp,
      },
      purchaseTerms: true,
      personalTerms: true,
      vat: null,
      delivery: null,
      newsletter: false,
    };
  },

  async created() {
    if (!this.customerValidated) {
      this.$router.push({ name: 'checkoutCustomerType' });
    }

    this.vat = await this.setVat();
    this.delivery = await this.setDelivery();
  },

  computed: {
    ...mapState(['currency']),
    ...mapState('checkout', ['customer', 'customerValidated', 'subscribed']),

    deliveryPrice() {
      return this.delivery ? this.delivery.price : null;
    },

    formatedCustomer() {
      const customer = { ...this.customer };
      customer.country = customer.country.name_sv;



      return customer;
    },

    formBlueprint() {
       console.log(this.customer, "CUSTOMER")
      if (this.customer.type === 'personal') {
        return PersonalBp;
      }

      if (this.customer.country.code === 'SE') {
        return CompanySeBp;
      }

      // Type 3, company without VAT number in other EU country
      if (this.customer.type === "company" && this.customer.orgnr === "" && this.customer.country.is_eu === 0) {
        return CompanySeBp;
      }
     

     

      return CompanyBp;
    },
  },

  methods: {
    ...mapActions('checkout', [
      'createOrder',
      'reset',
      'setDelivery',
      'setVat',
      'setSubscribed',
      'setSubscribedFalse',
    ]),
    ...mapActions('cart', ['empty']),

    async submit() {
      if (newsletter === true) {
        await this.setSubscribed();
      } else {
        await this.setSubscribedFalse();
      }
      console.log('clicked submit');
      try {
        const purchaseRef = await this.createOrder();
        this.reset();
        this.empty();
        this.nextStep(purchaseRef);
      } catch (err) {
        console.log(err);
        this.hasError = true;
        this.errorMsg = 'Something went wrong when creating the purchase';
      }
    },

    async nextStep(ref) {
      this.$router.push({ name: 'payment', params: { ref } });
    },
  },

  components: {
    FormBuilder: Builder,
    CartSummary,
  },

  mounted() {
    document.title = this.$t("checkout.order");
  },
};
</script>

<template>
  <div class="my-6 mx-auto max-w-xl">
    <div class="text-left">
      <p class="text-4xl">{{ $t('checkout.order') }}</p>
    </div>

    <CartSummary
      :showTotal="true"
      :showVat="true"
      :vat="vat"
      :delivery="deliveryPrice"
      :isEditable="false"
    />

    <div class="bg-white shadow-md px-8 pt-6 pb-8 mb-4">
      <form-builder
        ref="form"
        :disabled="true"
        v-model="formatedCustomer"
        :blueprint="formBlueprint"
      />
    </div>

    <div class="bg-white shadow-md px-8 pt-6 pb-8 mb-4">
      <!-- Köpvillkor -->
      <div class="md:flex flex-col text-left">
        <label class="block text-gray-500 font-bold">
          <input class="mr-2 leading-tight" type="checkbox" v-model="purchaseTerms" />
          <span class="text-sm">{{ $t('checkout.readAndAccept') }}</span>
        </label>

        <!--        <label class="block text-gray-500 font-bold">-->
        <!--          <input-->
        <!--            class="mr-2 leading-tight"-->
        <!--            type="checkbox"-->
        <!--            v-model="personalTerms"-->
        <!--          />-->
        <!--          <span class="text-sm"-->
        <!--            >{{$t("checkout.readAndAcceptPersonal")}}</span-->
        <!--          >-->
        <!--        </label>-->

        <label class="block text-gray-500 font-bold">
          <input class="mr-2 leading-tight" type="checkbox" v-model="newsletter" />
          <span class="text-sm">{{ $t('checkout.subscribe') }}.</span>
        </label>
      </div>
    </div>

    <div class="flex justify-between">
      <localized-link
        :to="{ name: 'checkoutInformation' }"
        class="bg-gray-500 hover:bg-gray-600 text-white my-4 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
      >
        {{ $t('checkout.button.back') }}
      </localized-link>

      <button
        v-if="!purchaseTerms || !personalTerms"
        class="bg-blue-500 text-white my-4 font-bold py-2 px-4 rounded opacity-50 cursor-not-allowed"
      >
        {{ $t('checkout.button.toPayment') }}
      </button>

      <button
        v-else
        class="bg-blue-500 hover:bg-blue-700 text-white my-4 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        @click="submit"
      >
        {{ $t('checkout.button.toPayment') }}
      </button>
    </div>
  </div>
</template>
